<template>
  <div class="text-xs text-white m-3">
    <canvas
      ref="textureCanvas"
      height="300px"
    />
  </div>
</template>
<script>
export default {
  name: 'TexturePreview',

  props: {
    texture: {
      required: true
    }
  },

  data () {
    return {
      title: ''
    }
  },

  mounted () {
    let canvas = this.$refs.textureCanvas

    if (canvas !== undefined) {
      let context = canvas.getContext('2d')
      let scale = canvas.width / this.texture.width
      this.title = this.texture.sourceFile
      if (this.texture.data === undefined) {
        context.drawImage(this.texture, 0, 0, this.texture.width * scale, this.texture.height * scale)
      }
    }
  }
}
</script>
